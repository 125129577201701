<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-trust-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative z-index-2">
        <div class="row justify-content-lg-between mb-7 align-items-center">
          <div class="col-md-6 col-lg-5">
            <!-- Info -->
            <div class="mb-5">
              <span class="d-block small font-weight-bold text-cap mb-2">TRUST &amp; SECURITY</span>
              <h1>We Value Trust With Our Partners &amp; Clients</h1>
            </div>

          <!-- End Info -->
          </div>

          <div class="col-md-6 d-md-inline-block">
            <!-- SVG Illustration -->
            <figure class="w-100" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/svg/illustrations/trust-hero.svg">
            </figure>
            <!-- End SVG Illustration -->
          </div>
        </div>
      </div>
      <!-- SVG Shape -->
      <!-- <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure> -->
      <!-- End SVG Shape -->
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Cert ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/trust-bg.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Certificates</span>
          <h2 class="mb-3">Compliance Certifications, Standards, and Regulations</h2>
          <p>We obtain industry-accepted certifications and comply with current industry standards and regulations.</p>
        </div>
        <div class="w-md-80 mx-md-auto">
          <a id="ga-trust-iso-card" class="card mb-5" href="#" data-toggle="modal" data-target="#iso27001Cert">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-12 col-lg-6 text-center mb-3 mb-lg-0">
                  <img class="img-fluid" src="@/assets/img/certificates/ISO27001.jpg">
                </div>
                <div class="col-12 col-lg-6">
                  <h4>beNovelty is ISO/IEC 27001:2013 Certified</h4>
                  <p>In the provision of API (Application Programming Interface) platform service, consisting of:</p>
                  <ul class="text-body">
                    <li>API Service Subscription Management</li>
                    <li>API Provider Onboarding and Offboarding</li>
                    <li>API Subscriber Onboarding and Offboarding</li>
                    <li>API Service Onboarding and Offboarding</li>
                    <li>API Platform Software Development</li>
                  </ul>
                </div>
              </div>
            </div>
          </a>
          <div class="row justify-content-lg-between">
            <div class="col-12 col-md-6 mb-5 mb-lg-0">
              <a id="ga-trust-hkqaa-startup-plus-card" class="card h-100" href="#" data-toggle="modal" data-target="#hkqaaCert">
                <div class="card-body text-center">
                  <img class="img-fluid" src="@/assets/img/certificates/hkqaa-hkregistration.jpg">
                  <p>HKQAA Hong Kong Registration Platinum Start-up Plus (2020)</p>
                </div>
              </a>
            </div>
            <div class="col-12 col-md-6">
              <a id="ga-trust-duns-card" class="card h-100" href="#" data-toggle="modal" data-target="#dunsCert">
                <div class="card-body text-center">
                  <img class="img-fluid" src="@/assets/img/certificates/duns-registered.jpg">
                  <p>D-U-N-S® Registered™</p>
                </div>
              </a>
            </div>
          </div>

        </div>
        <!-- Modal - iso27001Cert -->
        <div id="iso27001Cert" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="iso27001CertTitle" aria-hidden="true">
          <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="wantedlyPressTitle" class="modal-title">ISO/IEC 27001:2013 Certificate</h5>
                <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                  <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <!-- <div class="w-md-50 mx-auto">
                  <img class="img-fluid shadow-sm" src="@/assets/img/certificates/ISO27001-cert-1.jpg">
                </div> -->
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <img class="img-fluid shadow-sm mb-3 mb-lg-0" src="@/assets/img/certificates/ISO27001-cert-1.jpg">
                  </div>
                  <div class="col-12 col-lg-6">
                    <img class="img-fluid shadow-sm" src="@/assets/img/certificates/ISO27001-cert-2.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal - hkqaaCert -->
        <div id="hkqaaCert" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="hkqaaCertTitle" aria-hidden="true">
          <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="wantedlyPressTitle" class="modal-title">HKQAA Hong Kong Registration Platinum Start-up Plus (2020) Certificate</h5>
                <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                  <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div class="w-md-50 mx-auto">
                  <img class="img-fluid" src="@/assets/img/certificates/hkqaa-hkregistration-cert.jpg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal - dunsCert -->
        <div id="dunsCert" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dunsCertTitle" aria-hidden="true">
          <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="wantedlyPressTitle" class="modal-title">Dun &amp; Bradstreet D-U-N-S® Registered™ Business Certificate</h5>
                <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                  <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                  </svg>
                </button>
              </div>
              <div class="modal-body">
                <div class="w-md-90 mx-auto">
                  <img class="img-fluid shadow-sm" src="@/assets/img/certificates/duns-registered-cert.jpg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Cert ========== -->

    <!-- ========== Awards Section ==========-->
    <div
      class="space-top-3"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">Our Awards</span>
        <h2>Awards &amp; Accolades</h2>
      </div>
      <div class="position-relative">
        <div class="container space-2">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2\&quot;></span>&quot;,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div v-for="item in award_item_list" :key="item.title" class="js-slide">
              <div class="card bg-img-hero w-100" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-6.svg') + ')'}">
                <div class="card-body text-center pb-0">
                  <div class="avatar avatar-xxl mb-3">
                    <img class="avatar-img" :src="require('@/assets/img/awards/' + item.image_url)">
                  </div>
                  <h3 class="text-white text-center">{{ item.title }}</h3>
                  <p class="text-white text-center">{{ item.subtitle }}</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <hr class="solid">
                  <h4 class="text-white-70 text-center">{{ item.organization }}</h4>
                  <span class="text-white-70 d-block font-weight-bold text-center">{{ item.year }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 w-md-65 bg-light position-absolute top-0 right-0 bottom-0 rounded-left z-index-n1" />
      </div>
    </div>
    <!-- ========== End Awards Section ========== -->

    <!-- ========== Testimonials ========== -->
    <div class="container space-1 space-top-3" data-aos="fade-up">
      <div class="position-relative bg-light space-2 rounded shadow" :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-8.svg') + ')'}">
        <!-- Title -->
        <div class="w-md-80 w-lg-80 text-center mx-auto mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">testimonials</span>
          <h2>What Our Partners Say</h2>
        </div>
        <!-- End Title -->
        <PartnerSayCarousel :items="testimonial_list" />
      </div>
    </div>
    <!-- ========== End Testimonials ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">you're in good company</span>
        <h2>Powering Innovative Enterprises</h2>
      </div>

      <PartnerCarousel :items="partner_list" />

      <div class="mt-4">
        <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
      </div>
    </div>
    <!-- ========== End CTA Section ==========  -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import PartnerCarousel from '@/components/partner-carousel'
import PartnerSayCarousel from '@/components/partner-say-carousel'
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Trust',
  components: {
    PartnerCarousel,
    PartnerSayCarousel,
    ContactUsBar
  },
  data() {
    return {
      partner_list: [],
      testimonial_list: [],
      award_item_list: [
        {
          image_url: 'ciia-2022.jpg',
          title: 'Corporate Innovation Index (CII) Awards 2022',
          subtitle: 'Three-Star (Top Rank) Award',
          organization: 'CUHK Business School',
          year: '2022'
        },
        {
          image_url: 'fintech-awards-2020.jpg',
          title: 'Fintech Awards 2020',
          subtitle: 'Outstanding Cloud-Based API Platform',
          organization: 'ET Net',
          year: '2020'
        },
        {
          image_url: 'hk-ict-awards-2020-silver.jpg',
          title: 'Hong Kong ICT Awards 2020',
          subtitle: 'ICT Startup (Software and Apps) Silver Award',
          organization: 'OGCIO',
          year: '2020'
        },
        {
          image_url: 'ifta-2019.jpg',
          title: 'IFTA Fintech Achievement Awards 2019',
          subtitle: 'Cloud Computing Platinum Award',
          organization: 'IFTA',
          year: '2020'
        },
        {
          image_url: 'erb-md.jpg',
          title: 'ERB Manpower Developer Award',
          subtitle: 'Manpower Developer (2020-2022)',
          organization: 'Employees Retraining Board (ERB)',
          year: '2020'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
    this.partner_list = this.$store.getters.partners.partner_list
    this.testimonial_list = this.$store.getters.partners.testimonial_list
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Trust & Security | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/trust' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Trust & Security | beNovelty' },
        { property: 'og:description', content: 'Discover how we value Trust with our partners & clients.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/trust.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/trust' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
